import "./App.css";
import { BrowserRouter } from "react-router-dom";
import ProjectRoutes from "./Routes/routes";
import "../src/Styles/indexResponsive.css";

import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from "@mui/material/Fade";
import { Link } from "react-router-dom";
import { Img } from "./subComponents/img";

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function App(props) {
  const gotoTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <BrowserRouter>
        <ProjectRoutes />
        <div className="go_to_top">
          <div className="inner_go_to_top">
          {/* <div>WhatsApp Us</div> */}
          <div className="fixed_whatsapp shadow">
            <Link to={"#"} target="blank">
              <div className="social_media" style={{width:"50px", height:"50px"}} >
               <a href="https://wa.me/919002690068?text=" target="_blank" rel="noreferrer"> <Img
                  src={"/assets/images/Home/icons/whatsapp.png"}
                  alt={"whatsapp"}
                />
                </a>
              </div>
            </Link>
          </div>

          <div onClick={gotoTop}>
            <ScrollTop {...props}>
              <Fab size="small" aria-label="scroll back to top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  height="2.2rem"
                  style={{ color: "#fff" }}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M11.5 19V6.921l-5.792 5.793L5 12l7-7l7 7l-.708.714L12.5 6.92V19z"
                  />
                </svg>
              </Fab>
            </ScrollTop>
          </div>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
