
import React from "react";
import { useRoutes } from "react-router-dom";
import AboutUspage from "../Pages/AboutUsPage/aboutUs";
import ContactUsPage from "../Pages/ContactUsPage/contactUsPage";
import BlogsPage from "../Pages/BlogsPage/blogsPage";
import ProductPage from "../Pages/ProductPage/productPage";
import HomePage from "../Pages/HomePage/homePage";
import { GlassPartitionPage } from "../Pages/ProductPage/productspages/glassPartitionPage";
// import { LoremProduct } from "../Pages/ProductPage/productspages/SingleProducts/loremproduct";
import { UpvcProductPage } from "../Pages/ProductPage/productspages/upvcproduct";
import { HomeInterior } from "../Pages/ProductPage/productspages/HomeInterior/homeInterior";
import { TermConditionspage } from "../Pages/TermsCondition/terms&ConditionsPage";
import { PravicyPolicy } from "../Pages/TermsCondition/pravicyPolicy";
import useScrollToTop from "../CommonComponents/useScrolletoTop";
import { BlogDetailsPage } from "../Pages/BlogsPage/BlogDetailsPage";
import UpvcVariantPage from "../Pages/ProductPage/productspages/UPVCpages/upvcVariantPage";
import { ProductDetailsPage } from "../Pages/ProductPage/productspages/SingleProducts/productDetailspage";
import UpvcDoorPage from "../Pages/ProductPage/productspages/UPVCpages/upvcDoorPage";
import UpvcDoorWindowAccessrios from "../Pages/ProductPage/productspages/UPVCpages/upvcDoorWindowAccessories";
import GlassPartition from "../Pages/ProductPage/productspages/commercialPartialSystem/commercialPartionSystem";
import ProfileAndAccessories from "../Pages/ProductPage/productspages/commercialPartialSystem/profileAndAccessories";
import AccessoriesShopPage from "../Pages/ProductPage/productspages/SingleProducts/accessoriesShopPage";
import RecentProject from "../Pages/ProductPage/productspages/SingleProducts/recentProject";
import AccessoriesPage from "../Pages/ProductPage/productspages/SingleProducts/accessoriesPage";
import RecentProjectPage from "../Pages/RecentProjectPage/recentProjectPage";
import Testimonial from "../Pages/Testimonial/testimonial";

function ProjectRoutes(){
    useScrollToTop();

    const elements = useRoutes([
        {path:"/", element:<HomePage/>},
        {path:"/about-us", element:<AboutUspage/>},
        {path:"/locate-us", element:<ContactUsPage/>},
        {path:"/blogs", element:<BlogsPage/>},
        // {path:"/product", element:<ProductPage/>},
        // {path:"/accessories", element:<AccessoriesPage/>},
        // {path:"/glass-partition", element:<GlassPartitionPage/>},
        {path:"/products/:slug", element:<ProductDetailsPage/>},
        {path:"/upvc", element:<UpvcProductPage/>},
        {path:"/:slug", element:<HomeInterior/>},
        {path:"/terms-&-Condition", element:<TermConditionspage/>},
        {path:"/privacy-policy", element:<PravicyPolicy/>},
        {path:"/blog-details-page/:slug", element:<BlogDetailsPage/>},
        {path:"/:slug/:slug", element:<UpvcVariantPage/>},
        {path:"/upvc_door", element:<UpvcDoorPage/>},
        {path:"/upvc-door-window-accessries", element:<UpvcDoorWindowAccessrios/>},
        // {path:"/:slug/:slug", element:<GlassPartition/>},
        // {path:"/profle-accessories", element:<ProfileAndAccessories/>},
        {path:"/accessories-shop/:slug", element:<AccessoriesShopPage/>},
        {path:"/accessories/:slug", element:<AccessoriesPage/>},
        {path:"/recent-project/:slug", element:<RecentProject/>},
        {path:"/all-recent-project", element:<RecentProjectPage/>},
        {path:"/testimonial", element:<Testimonial/>}
    ]);

    return elements;

}
export default ProjectRoutes;