import React from "react";
import Header from "../../CommonComponents/header";
import { Footer } from "../../CommonComponents/footer";
import { AboutHeroSection } from "./Components/aboutHeroSection";
import { ChairManMessageSection } from "./Components/chairManMessageSection";
import { StorySection } from "./Components/storySection";
import { VisionMissionSection } from "./Components/vision&missionSection";
import { MobileHeader } from "../../CommonComponents/mobileHeader";


function AboutUspage(){
    return(
        <>
        <Header/>
        <MobileHeader/>
        <div className="mobile_header_spacer"></div>
        <div className="desktop_header_spacer"></div>
           <AboutHeroSection/>
           <div id="chairman-message"></div>
           <ChairManMessageSection />
           <div id="our-story"></div>
           <StorySection/>
           <div id="vision-&-mission"></div>
           <VisionMissionSection/>
         <Footer/>
        </>
    )
}
export default AboutUspage;









