import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";
import { BlogCard } from "./blogCard";
import { baseurl } from "../../../../CommonComponents/baseurl";

import Skeleton from '@mui/material/Skeleton';


export default function BlogSlider() {
  // api data fetching start from here
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const formData = new FormData();
        formData.append("blog_page", currentPage);

        const response = await fetch(`${baseurl}/api/blog-list`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data.success === "1") {
          setBlogs(data.data);
          setTotalPages(data.pagination_data.total_pages);
        } else {
          console.error("Failed to fetch blog data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBlogs();
  }, [currentPage]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  // api data fetching close from here

  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        // centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
          },
          1654: {
            slidesPerView: 5,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper blog_slider"
      >
        {
          blogs === null ?
          <div className="skeleton_blog_container" style={{width:"100%"}}>
          <Skeleton variant="rectangular" width="32%" height={"300px"}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
        <Skeleton variant="rectangular" width="32%" height={"300px"}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
        <Skeleton variant="rectangular" width="32%" height={"300px"}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
          </div>
           :
        blogs.map((items) => {
          return (
            <>
              <SwiperSlide>
                <BlogCard
                  discription={items.title}
                  navigation={`/blog-details-page/${items.slug}`}
                  date={formatDate(items.datetime)}
                  imageurl={items.image}
                />
              </SwiperSlide>
            </>
          );
        })}
      </Swiper>
    </>
  );
}
