import React from "react";
import { Img } from "../../../../subComponents/img";
import { Text } from "../../../../subComponents/text";
import { ReadMore } from "../../../../CommonComponents/readMore";
import { NavLink } from "react-router-dom";

const BlogCard = ({ discription, date, navigation, imageurl }) => {
  return (
    <>
      <div style={{ position: "relative" }} className="blogs_outer_container">
        <div className="blog_card">
          <div className="blog_image">
            <Img src={imageurl} alt={"blog"} />
          </div>
          <div className="blog_date_container">{date}</div>
        </div>

        <div className="blog_card_overlay">
          <div className="innerblog_card_overlay">
            <Text
              text={discription}
              style={{fontWeight: "600" }}
            />
            <NavLink to={navigation}>
              <ReadMore />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};
export { BlogCard };
