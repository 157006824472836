import React from "react";
import Header from "../../CommonComponents/header";
import { Footer } from "../../CommonComponents/footer";
import { MobileHeader } from "../../CommonComponents/mobileHeader";
import { BlogHeroSection } from "../BlogsPage/Components/blogHeroSection";
import { Text } from "../../subComponents/text";
import { Heading } from "../../subComponents/Heading";

const PravicyPolicy=()=>{
    return(
        <>
          <Header/>
          <MobileHeader/>
          <BlogHeroSection pagetitle={"Pravicy Policy"} pagedescription={"Lorem ipsum dolor sit amet, consectetur  adipiscing"} />
          <section className="terms_condition_section">
            <Text text={"Terms and Conditions Please read these terms and conditions carefully before using Our Service."} />
            <Text text={"Interpretation and Definitions"} />
            <br />
            <Heading title={"Interpretation"} />
            <br />
            <Text text={"The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural."} />
            <br />
            <Heading title={"Definitions"} />
            <br />
            <Text text={"For the purposes of these Terms and Conditions:"} />
            <br />
            <Text highlightText="Affiliate " text={"means an entity that controls, is controlled by or is under common control with a party, where “control” means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority."} />
            <br />
            <Text highlightText={"Country"} text={"refers to: Haryana, India"}/>
            <br />
            <Text highlightText={"Company "} text={"refers to Alponix Private Limited."}  />
            <br />
            <Text highlightText={"Device "} text={"means any device that can access the Service such as a computer, a cellphone or a digital tablet."} />
            <br />
            <Text highlightText={"Service "} text={"refers to the Website."} />
            <br />
            <Text highlightText={"Terms and Conditions"} text={"mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. "} />
            <br />
            <Text highlightText={"Website "} text={"refers to Alponix Private Limited, accessible from https://alponix.com/"} />
            <br />
            <Text text={"Acknowledgment"}/>
            <br />
            <Text text={"These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service."} />
            <br />
            <Text text={"Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service."} />
            <br />
            <Text text={"By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service."} />
            <br />
            <Text text={"You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service."} />
            <br />
            <Text text={"Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service."} />
             <br />
            <Text text={"Links to Other Websites"} />
            <br />
            <Text text={"Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company."} />
            <br />
            <Text text={"The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services."} />
            <br />
            <Text text={"We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit."} />
            <br />
            <Text text={"Termination"} />
            <br />
            <Text text={"We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions."} />
            <br />
            <Text text={"Upon termination, Your right to use the Service will cease immediately."} />
            <br />
            <Text text={"Limitation of Liability"} />
            <br />
            <Text text={"Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven’t purchased anything through the Service."} />
            <br />
            <Text text={"To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose."} />
            <br />
            <Text text={"Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party’s liability will be limited to the greatest extent permitted by law."} />
            <br />
            <Text text={"Without limiting the foregoing, neither the Company nor any of the company’s provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components"} />
            <br />
            <Text text={"Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law."} />
            <br />
            <Text text={"Governing Law"} />
            <br />
            <Text text={"The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws."} />
            <br />
            <Text text={"Disputes Resolution"} />
            <br />
            <Text text={"If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company."} />
            <br />
            <Text text={"Translation Interpretation"} />
            <br />
            <Text text={"These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute."} />
            <br />
            <Text text={"Changes to These Terms and Conditions"} />
            <br />
            <Text text={"We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion."} />
            <br />
            <Text text={"By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service."} />
            <br />
            <Text text={"Contact Us"} />
            <br />
            <Text text={"If you have any questions about these Terms and Conditions, You can contact us:"} />
            <br />
            <Text text={"By email: support@alponix.com"} />
          </section>
          <Footer/>
        </>
    );
}
export {PravicyPolicy};