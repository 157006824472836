import React from "react";
import { SingleProduct } from "../SingleProducts/singleProducts";
import Header from "../../../../CommonComponents/header";
import { MobileHeader } from "../../../../CommonComponents/mobileHeader";
import { Footer } from "../../../../CommonComponents/footer";
import { Heading } from "../../../../subComponents/Heading";
import ProductOptionSection from "../../Components/productOptionSection";
import UpvcVariantBannerSlider from "../../Components/upvcvariantbannerSlider";
import { Img } from "../../../../subComponents/img";
// import productVariantImagesection from "../../Components/productVariantImageSection";

const UpvcDoorWindowAccessrios= () => {
  return (
    <>
      <Header />
      <MobileHeader />
      <br />
      <SingleProduct
        imageUrl="assets/images/product/windon_banner.png"
        pagetitle1="uPVC"
        pagetitle2={"-Window and Door Accessories "}
      />
      <ProductOptionSection sectionTitle="Accessories" buttontitle1="Window glass" buttontitle2="Mesh and Grill" buttontitle3="Window handles Locks" buttontitle4="Colour Shades" />
      <br />
<UpvcVariantBannerSlider/>

      <Footer />
    </>
  );
};
export default UpvcDoorWindowAccessrios;
