import { React, useEffect, useRef, useState } from "react";
import { Img } from "../../../../subComponents/img";
import { Subheading } from "../../../../subComponents/subHeading";
import { Heading } from "../../../../subComponents/Heading";
import { ServiceCard } from "./card";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import {Autoplay, Pagination } from "swiper/modules";

import AOS from "aos";
import "aos/dist/aos.css";

const ServiceSection = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  
  return (
    <>
      <section className="home_service_section">
        <div className="home_service_container">
          <div className="home_service_left_image">
            <Img
              src={"assets/images/Home/professional window.png"}
              alt={"lprofessional window"}
            />
          </div>
          <div className="home_service_right_image">
            <Img
              src={"assets/images/Home/professional window.1png.png"}
              alt={"lprofessional window"}
            />
          </div>
        </div>
        <div className="home_service_section_overlay">
          <div
            style={{
              height: "max-content",
              width: "max-content",
              margin: "auto",
            }}
          >
            <Subheading title={"SERVICE MAKE YOU HAPPIER LIFE !"} style={{fontSize:"1rem",color:"#FA853D"}}  />{" "}
          </div>
          <div className="service_page_title" style={{}}>
            <Heading
              style={{ textTransform: "Capitalise" }}
              title={"Professional Window And Door Useful Services"}
            />{" "}
          </div>
          <div className="home_service_card_container">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              loop="true"
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                546: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay,Pagination]}
              className="mySwiper service_swiper_slider"
            >
              <SwiperSlide>
                <ServiceCard
                  iconUrl="assets/images/Home/service_icon1.png"
                  title={"INSTALLATION"}
                  description="Precision installation of a wide variety of windows and doors."
                />
              </SwiperSlide>

              <SwiperSlide>
                <ServiceCard
                  iconUrl="assets/images/Home/icon image.png"
                  title={"REPAIR"}
                  description="Quick and efficient repairs for damaged or malfunctioning windows."
                  style={{width:"15rem"}}
                />
              </SwiperSlide>

              <SwiperSlide>
                <ServiceCard
                  iconUrl="assets/images/Home/icon image 2.png"
                  title={"CUSTOMIZATION"}
                  description="Install custom windows & doors to perfectly match your home."
                />
              </SwiperSlide>

            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};
export { ServiceSection };


