import React, { useEffect, useState } from "react";
import Header from "../../../../CommonComponents/header";
import { MobileHeader } from "../../../../CommonComponents/mobileHeader";
import { Footer } from "../../../../CommonComponents/footer";
import UpvcVariantSubComp from "./upvcVariantsubPages";

const UpvcVariantPage = () => {

  return (
    <>
      <Header />
      <MobileHeader />
      <div className="desktop_header_spacer"></div>
      <br />
      <UpvcVariantSubComp/>
      <br /><br />
      <Footer />
    </>
  );
};

export default UpvcVariantPage;




