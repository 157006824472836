import React, { useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';


const UpvcVariantBannerSlider = ({ imageurl }) => {


  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-bs-ride="carousel"
      style={{ maxHeight: "600px", maxWidth: "1400px", margin: "auto", marginTop:"1.5rem" }}
    >
      <div className="carousel-inner">
        {imageurl === null ? (
                      <Skeleton variant="rectangular" width="100%" height={"100%"}>
                      <div style={{ paddingTop: '57%' }} />
                    </Skeleton>
        ) : imageurl && imageurl.length > 0 ? (
          imageurl.map((url, index) => (
            <div
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              key={index}
            >
              <img
                src={url}
                className="d-block w-100 slider_image_banners"
                alt={`Slide ${index}`}
                style={{ maxHeight: "500px" }}
              />
            </div>
          ))
        ) : (
          <div className="carousel-item active">
            <img
              src={imageurl.image}
              className="d-block w-100 slider_image_banners"
              alt="Default slide"
              style={{ maxHeight: "500px" }}
            />
          </div>
        )}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon shadow" aria-hidden="true" style={{width:"50px", height:"50px", borderRadius:"50%", backgroundColor:"#FA853D", fontSize:"0.5rem"}}></span>
        <span className="visually-hidden"  >Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon shadow" aria-hidden="true" style={{width:"50px", height:"50px", borderRadius:"50%", backgroundColor:"#FA853D", fontSize:"0.5rem"}}></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default UpvcVariantBannerSlider;
