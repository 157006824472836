import React, { useEffect } from "react";
import { Heading } from "../../../subComponents/Heading";
import { Img } from "../../../subComponents/img";
import { Text } from "../../../subComponents/text";
import AOS from "aos";
import "aos/dist/aos.css";

const ChairManMessageSection = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <section className="message_section" >
        <Heading
          orangeheading={"Chairman’s"}
          title={"Message"}
          style={{ textAlign: "center", paddingBottom: "2rem" }}
          data-aos="zoom-in"
        />
        <div
          className="inner_message_container"
          style={{ overflowX: "hidden" }}
        >
          {/* message container left start from here */}
          <div className="message_container_left" data-aos="fade-right">
            <div className="chairmain_image">
              <Img
                src={"assets/images/AboutUs/chairman image.png"}
                alt={"chairman image"}
              />
            </div>
          </div>
          {/* message container left close from here */}

          {/* message container right start from here*/}
          <div className="message_container_right" data-aos="fade-left">
            <div className="innermessage_container">
              <Text
                text={`
                  Since founding Alu Empire in 2013, Mr. Jatin Gupta has driven the company’s growth and success. Under his leadership, Alu Empire has become a market leader in UPVC and aluminum doors and windows.
Mr. Gupta's innovative ideas in interior decor and marketing ensure the company's profitability and sustainability. His strategic vision fosters continuous improvement and excellence. Believing in the power of a great team, Mr. Gupta has built a skilled workforce dedicated to high standards. His leadership inspires Alu Empire to achieve new heights, with a focus on customer satisfaction and industry-leading innovation.
                            `}
                style={{ fontSize: "1rem" }}
              />
            </div>
          </div>
          {/* message container right close from here */}
        </div>
      </section>
    </>
  );
};
export { ChairManMessageSection };
