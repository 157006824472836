import React, { useEffect, useState } from "react";
import Header from "../../CommonComponents/header";
import { Footer } from "../../CommonComponents/footer";
import { MobileHeader } from "../../CommonComponents/mobileHeader";
import { BlogHeroSection } from "../BlogsPage/Components/blogHeroSection";
import AOS from "aos";
import "aos/dist/aos.css";
import { baseurl } from "../../CommonComponents/baseurl";
import { NavLink } from "react-router-dom";
import { Img } from "../../subComponents/img";
import { Subheading } from "../../subComponents/subHeading";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const RecentProjectPage = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [currentbanner, setCurrentBanner] = useState(null);

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await fetch(`${baseurl}/api/our-projects`, {
          method: "GET",
        });

        const data = await response.json();
        if (data.success === "1") {
          setCurrentBanner(data.data);
        } else {
          console.error("Failed to currentbanner data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBanner();
  }, []);

  useEffect(() => {
    console.log("currentbanner:", currentbanner);
  }, [currentbanner]);

  return (
    <>
      <Header />
      <MobileHeader />
      <div className="desktop_header_spacer"></div>
      <BlogHeroSection
        pagetitle={"RECENT PROJECTS"}
        pagedescription={
          "See what we've done: innovation, excellence, and results that inspire"
        }
      />

      <div className="recent_project_page_section">
        {currentbanner === null ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress style={{ color: "#000", margin: "auto" }} />
          </Box>
        ) : (
          currentbanner.map((items) => {
            return (
              <>
                {/* <NavLink to={`/recent-project/${items.slug}`}> */}
                <div
                  className="recent_projects_all"
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  // style={{backgroundColor:"#fff"}}
                  // style={{ borderRadius: "1.5rem", overflow: "hidden" }}
                >
                  <Img src={items.image} style={{ zIndex: "-1" }} />

                  <NavLink
                          to={`/recent-project/${items.slug}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="recent_project_button">
                          <Subheading
                            title={items.title}
                            style={{
                              textTransform: "uppercase",
                            }}
                          />
                          </div>
                        </NavLink>

                  {/* <div className="recent_project_overlay">
                    <div className="outer_recent_project_overlay">
                      <div className="inner_recent_project_overlay">
                        <NavLink
                          to={`/recent-project/${items.slug}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Subheading
                            title={items.title}
                            style={{
                              color: "#fff",
                              textTransform: "uppercase",
                            }}
                          />
                        </NavLink>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* </NavLink> */}
              </>
            );
          })
        )}
      </div>
      <div style={{ height: "3rem" }}></div>
      <Footer />
    </>
  );
};
export default RecentProjectPage;
