import React from "react";
import {Heading} from "../../../subComponents/Heading";
import { NavLink } from "react-router-dom";

const ContactHeroSection=()=>{
    return(
        <>
          <section className="contact_hero_section">
           <div className="contact_hero_content">
            <div>
                <Heading style={{textAlign:"center",color:"#fff"}} title={"Contact Us"} />
                 <p style={{textAlign:"center", color:"#fff"}} ><NavLink to="/" style={{textDecoration:"none"}} ><span style={{color:"#FA853D"}}>Home</span></NavLink> / Contact</p>
            </div>
              <p style={{textAlign:"center", color:"#fff"}} >We provide the best services for your doors & windows.</p>
           </div>
          </section>
        </>
    );
}
export {ContactHeroSection};