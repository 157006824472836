import React from "react";
import Header from "../../CommonComponents/header";
import { Footer } from "../../CommonComponents/footer";
import { ContactHeroSection } from "./Components/contactHeroSection";
import { GetInTouchSection } from "./Components/getInTouchSection";
import { Heading } from "../../subComponents/Heading";
import { MobileHeader } from "../../CommonComponents/mobileHeader";

function ContactUsPage() {
  return (
    <>
      <Header />
      <MobileHeader/>
      <div className="mobile_header_spacer_home"></div>
      <div className="desktop_header_spacer"></div>
      <ContactHeroSection />
      <GetInTouchSection />
      <section className="contact_map_section">
        <Heading title={"Find us on google map"} />
        <br />
        <div className="contact_map_container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d56163.174471376515!2d77.22929938178875!3d28.383074855003354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d28.3629015!2d77.2903259!4m5!1s0x390cdf679be5a621%3A0xc16d657b9ec65f6b!2salu%20empire!3m2!1d28.3933388!2d77.2494543!5e0!3m2!1sen!2sin!4v1725619265929!5m2!1sen!2sin"
            width="100%"
            height="500"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

{/* <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        </div>
      </section>
      <Footer />
    </>
  );
}
export default ContactUsPage;
