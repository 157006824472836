import React, { useEffect, useState } from "react";
import Header from "../../CommonComponents/header";
import { MobileHeader } from "../../CommonComponents/mobileHeader";
import { BlogHeroSection } from "../BlogsPage/Components/blogHeroSection";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import './styles.css';

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Clientcard } from "../HomePage/Components/OURCLIENTS/clientCard";
import { baseurl } from "../../CommonComponents/baseurl";
import { Footer } from "../../CommonComponents/footer";

const Testimonial = () => {
  const [currentsreviews, setReviews] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`${baseurl}/api/review-list`, {
          method: "GET",
        });

        const data = await response.json();
        if (data.success === "1") {
          setReviews(data.data);
        } else {
          console.error("Failed to fetch clients reviews data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductDetails();
  }, []);

  return (
    <>
      <Header />
      <MobileHeader />
      <div className="desktop_header_spacer"></div>
      <BlogHeroSection
        pagetitle={"Testimonial"}
        pagedescription={
          "Join the satisfied crowd and experience excellence, quality, and unmatched service."
        }
      />
<br />
      <div>
        <div className="our_clients_card_containers">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop="true"
            breakpoints={{
              200: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              546: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper client_slider"
          >
            {currentsreviews === null ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress style={{ color: "#fff", margin: "auto" }} />
              </Box>
            ) : (
              currentsreviews.map((items) => {
                return (
                  <>
                    <SwiperSlide>
                      <Clientcard
                        pharagraph={items.description}
                        clientname={items.client_name}
                        designation={items.designation}
                        imageurl={items.image}
                      />
                    </SwiperSlide>
                  </>
                );
              })
            )}
          </Swiper>
        </div>
      </div>


      <Footer/>
    </>
  );
};
export default Testimonial;
