import React, { useEffect, useState } from "react";
import Header from "../../CommonComponents/header";
import { Footer } from "../../CommonComponents/footer";
import { Img } from "../../subComponents/img";
import { Heading } from "../../subComponents/Heading";
import { Text } from "../../subComponents/text";
import { useParams } from "react-router-dom";
import { baseurl } from "../../CommonComponents/baseurl";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { MobileHeader } from "../../CommonComponents/mobileHeader";

const BlogDetailsPage = () => {
  const [blogs, setBlogs] = useState("");
  const { slug } = useParams();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const formData = new FormData();
        formData.append("slug", slug);

        const response = await fetch(`${baseurl}/api/blog-detail`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data.success === "1") {
          setBlogs(data.data);
        } else {
          console.error("Failed to fetch blog data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBlogs();
  }, [slug]);

  const blogPost = {
    title: blogs.title,
    description: blogs.description,
    status: blogs.status,
    category: blogs.category,
    datetime: blogs.datetime,
    image: blogs.image2,
  };

  return (
    <>
      <Header />
      <MobileHeader/>
      <div className="mobile_spacer"></div>
      <div className="desktop_header_spacer"></div>

      <section className="details_blog_banner">
        <div className="blogs_banner_container">
        {
          blogPost.image===""?
          <Box sx={{ display: "flex" }}>
          <CircularProgress style={{ color: "#000", margin: "auto" }} />
        </Box>:
          <Img src={blogPost.image} style={{ objectFit: "cover"}} height={"100%"} width={"100%"}  />
        }  
        </div>
      </section>

      <section className="blog_content_section">
        <Heading
          style={{ textTransform: "uppercase", textAlign: "left" }}
          title={blogPost.title}
        />
        <br />
        <p dangerouslySetInnerHTML={{ __html: blogPost.description }}></p>
        <br />
      </section>

      {/* <section className="blog_text_image_container">
        <div className="blog_content_container">
        <Heading
          title={"Lorem ipsum dolor sit amet, consectetur adipiscing"}
        />
<br />
<Text
          highlightText="Lorem ipsum dolor:"
          text={`
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
             incididunt ut labore et dolore magna aliqua....
       `}
        />
        <br />
        <Text
          highlightText="Lorem ipsum dolor:"
          text={`
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
             incididunt ut labore et dolore magna aliqua....
       `}
        />
        </div>

        <div className="blog_image_container" >
         <Img src={"/assets/images/Blog/blog_card.png"} />
        </div>
      </section> */}

      <Footer />
    </>
  );
};
export { BlogDetailsPage };
