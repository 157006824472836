import React, { useEffect } from "react";
import { Subheading } from "../../../../subComponents/subHeading";
import {Text} from "../../../../../src/subComponents/text";
import AOS from "aos";
import "aos/dist/aos.css";

const ContentCard=({content,title})=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return(
        <>
          <div className="content_card" data-aos="fade-right" >
             <Subheading title={title} />
             <div className="vision_content_card">
                 <Text text={content} />
             </div>
          </div>
        </>
    );
}
export {ContentCard};