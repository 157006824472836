import React from "react";
import { Heading } from "../../../subComponents/Heading";
import FilterOptionButton from "./filterOptionButton";
import ProductOptionCard from "./productOptionCard";

const ProductOptionSection=({sectionTitle,buttontitle1,buttontitle2,buttontitle3,buttontitle4})=>{
    return(
        <>
        <section className="product_option_section" >
        <div style={{width:"max-content",margin:"auto",padding:"0 0"}} >
            <Heading title={sectionTitle} />
         </div>

         <div className="fillter_option_button_container">
           <FilterOptionButton text={buttontitle1} style={{backgroundColor:"#FA853D", color:"#fff"}} />
           <FilterOptionButton text={buttontitle2} />
           <FilterOptionButton text={buttontitle3} />
           <FilterOptionButton text={buttontitle4} />
         </div>

         <div className="product_option_card_container" >
            <ProductOptionCard/>
            <ProductOptionCard/>
            <ProductOptionCard/>
            <ProductOptionCard/>
         </div>
         <div className="product_option_card_container" style={{marginTop:"1rem"}} >
            <ProductOptionCard/>
            <ProductOptionCard/>
            <ProductOptionCard/>
            <ProductOptionCard/>
         </div>
         <button className="load_more_button">Load More</button>
        </section>
        </>
    );
}
export default ProductOptionSection;