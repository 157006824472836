import React from "react";
import "../../../Styles/aboutUs.css";
import { Heading } from "../../../subComponents/Heading";
import { Text } from "../../../subComponents/text";
import { Img } from "../../../subComponents/img";

const AboutHeroSection=()=>{
    return(
        <>
          <section className="about_hero_section" >
            {/* about us hero left containner start from here */}
            <div className="about_hero_left_container">
                <div className="inner_about_left_container" >
                    <Heading orangeheading={"Experts in making quality uPVC"} title={"to your exact standards."} className={"heading_white"} />
                    <hr style={{color:"#fff", height:"2px"}} />
                    <Text text={`
                    Founded in 2013, Alu Empire is a premier provider of superior interior and design finish 
                    products. We specialize in delivering high-quality UPVC and aluminum solutions that 
                    enhance the aesthetic and functional value of both residential and commercial spaces.

                    Our comprehensive product range includes: UPVC Doors & Windows, Aluminium Doors & Windows, 
                    Railing Systems, Shower Cubicles, Commercial Partition Systems, Profiles.
                      `} style={{color:"#fff", fontWeight:"300"}} />
                </div>
            </div>
            {/* about us hero left conatiner close fromm here */}



            {/* about hero right container start from here */}
              <div className="about_hero_right_container">
                <Img src={"assets/images/AboutUs/about_hero_banner.jpg"} alt={"about hero banner"} />
              </div> 
            {/* about hero right container close from here */}
          </section>
        </>
    );
}
export {AboutHeroSection};