import React, { useEffect, useState } from "react";
import Header from "../../../../CommonComponents/header";
import { Footer } from "../../../../CommonComponents/footer";
import { ProductCategoryHeroSection } from "../../Components/productCategoryHeroSection";
import { RightImagesTextcard } from "../../Components/rightImageTextCard";
import { LeftImagesTextCard } from "../../Components/leftImagesTextcard";
import { WhyProductCategorySection } from "../../Components/whyProductCategorysection";
import { Heading } from "../../../../subComponents/Heading";
import { MobileHeader } from "../../../../CommonComponents/mobileHeader";
import { useParams } from "react-router-dom";
import { baseurl } from "../../../../CommonComponents/baseurl";

const HomeInterior = () => {
  const [currentcategory, setCurrentcategory] = useState([]);
  const [subcategorylist, setSubcategorylist] = useState([]);
  const { slug } = useParams();

  // useEffect(() => {
  //   const fetchCategory = async () => {
  //     try {
  //       const formData = new FormData();
  //       formData.append("slug", slug);

  //       const response = await fetch(`${baseurl}/api/sub-category`, {
  //         method: "POST",
  //         body: formData,
  //       });

  //       const data = await response.json();
  //       if (data.success === "1") {
  //         setCurrentcategory(data);
  //         setSubcategorylist(data.data);
  //       } else {
  //         console.error("Failed to fetch blog data:", data.message);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchCategory();
  // }, [slug]);

  useEffect(() => {
    const fetchCategory = async () => {
      if (!slug) return; // If slug is undefined, exit early
      try {
        const formData = new FormData();
        formData.append("slug", slug);
    
        const response = await fetch(`${baseurl}/api/sub-category`, {
          method: "POST",
          body: formData,
        });
    
        const data = await response.json();
        console.log("Fetched data:", data); // Debugging line
        if (data.success === "1") {
          setCurrentcategory(data);
          setSubcategorylist(data.data);
        } else {
          console.error("Failed to fetch data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchCategory();
  }, [slug]);
  

  useEffect(() => {
    console.log("Category state updated:", currentcategory);
  }, [currentcategory]);

  return (
    <>
      <Header />
      <MobileHeader />

<div className="desktop_header_spacer"></div>
      <ProductCategoryHeroSection
        title={currentcategory.page_title}
        content={currentcategory.page_description}
        show={false}
        showinterior={true}
        subcategorylist={subcategorylist}
        categoryslug={currentcategory.slug}
        bannerImage={currentcategory.page_banner}
      />

      {slug === "upvc-door-and-window" ? (
        <>
          <WhyProductCategorySection
            title={"Why we use uPVC doors and window?"}
            content={`        
Modern homes are increasingly choosing uPVC doors and windows because of their great energy 
efficiency, which lowers energy costs by preserving internal temperature consistency. Because
 uPVC resists rot, rust, and corrosion and can function reliably for a long time even in 
 adverse weather, it is well renowned for its durability. Furthermore, uPVC is a great
  option for homeowners looking for a hassle-free solution because it requires no upkeep. 
  Modern locking systems are frequently included with the sturdy, solid frames, enhancing house security. Additionally, 
uPVC provides superior sound insulation, resulting in a calmer and quieter living space.`}
imageurl={"assets/images/banners/Why we use.jpg"}
          />

          <section className="upvc_benefit_section">
            <div
              style={{
                width: "max-content",
                margin: "auto",
                padding: "2rem 0",
              }}
            >
              <Heading title={"Benefits of uPVC"} />
            </div>

            <RightImagesTextcard
              orangeheading={"Superior Insulation"}
              title=" - and Energy Efficiency"
              content={
                "Because uPVC windows and doors have superior insulation, you can use less energy and pay less for utilities. Use this energy-saving option to maintain a comfortable home all year long."
              }
              imageurl={"assets/images/banners/Benefit section 2.jpg"}
            />
            <LeftImagesTextCard
              orangeheading={"Low Maintenance"}
              title={"and Durability"}
              content={
                "uPVC is low maintenance and resistant to fading, rusting, and decay. It's a hassle-free option that offers long-lasting performance without requiring frequent repairs or maintenance."
              }
              imageurl={"assets/images/banners/Benefit section 3.jpg"}
            />
            <RightImagesTextcard
              orangeheading={"Enhanced"}
              title=" - Security and Safety"
              content={
                "Because uPVC frames are strong and long-lasting, your house will be more secure. You can feel secure knowing that your property is well-guarded from intruders when you install robust locking systems."
              }
              imageurl={"assets/images/banners/Benefit section 4.jpg"}
            />
            <LeftImagesTextCard
              orangeheading={"Cost-Effective"}
              title={"and Affordable"}
              content={
                "uPVC provides a high-quality substitute for conventional materials at a lower cost. Achieve the advantages of superior performance, longevity, and elegance for a significantly lower price."
              }
              imageurl={"assets/images/banners/Benefit section 5.jpg"}
            />
          </section>
        </>
      ) : (
        ""
      )}

      {slug === "alu-home-interior" ? (
        <>
          <WhyProductCategorySection
            title={"Why we use alu home interior?"}
            content={`     
Alu Home interior design goes beyond simply creating a visually appealing environment. 
It's about designing a space that expresses your individuality, fits your lifestyle, and boosts
 your general wellbeing. Essentially, interior design for homes is about making a property into a
  place that uplifts your spirit and improves your quality of life. The interior design of Alu Home
   fulfills several practical as well as decorative objectives. Our goal is to make your home seem better.
 It's about designing inspirational and useful environments that improve our quality of life.`}
 imageurl={'/assets/images/alu_empire_products/Why we use.jpg'}
          />
          <div
            style={{ margin: "auto", padding: "2rem 0", textAlign: "center" }}
          >
            <Heading title={"Benefits of ALU HOME INTERIOR"} />
          </div>
          <RightImagesTextcard
            orangeheading="Stylish"
            title=" - Aluminum"
            content={
              "Modern, elegant metal designs may transform your area while improving its functionality and appearance. Our products provide the ideal balance of durability and style for any interior design."
            }
            imageurl={"/assets/images/alu_empire_products/Benefit section 1.jpg"}
          />
          <LeftImagesTextCard
            orangeheading={"Customized"}
            title={"Interior Fit-Outs Available"}
            content={
              "Aluminum interiors that are custom-made to fit your specific preferences and spatial needs. We offer custom designs that flawlessly reflect your idea, guaranteeing a unique touch in every aspect."
            }
            imageurl={"/assets/images/alu_empire_products/Benefit section 3.jpg"}
          />
          <RightImagesTextcard
            orangeheading={"Green"}
            title=" - Solutions"
            content={
              "Sustainability is a priority in the construction of our aluminum interiors. We provide eco-friendly solutions that improve the environment without sacrificing quality by using recyclable materials."
            }
            imageurl={"/assets/images/alu_empire_products/Benefit section 4.jpg"}
          />
          <LeftImagesTextCard
            orangeheading={"Long-Lasting"}
            title={"Durability and Strength"}
            content={
              "Take advantage of aluminum's unparalleled durability for the interiors of your home. Our products provide you with long-term value and dependable performance since they are made to resist wear and tear."
            }
            imageurl={"/assets/images/alu_empire_products/Benefit section 6.jpg"}
          />
        </>
      ) : (
        ""
      )}

      {slug === "commercial-partition-system" ? (
        <>
          <WhyProductCategorySection
            title={"Why we use Commercial Partition System?"}
            content={`     
Modern office spaces require a commercial partition system for flexible and effective space management. In an open office setting, it permits the establishment of private work zones, meeting rooms, and collaboration spaces. These barriers are quite adaptable and come in a variety of materials, including wood, glass, and aluminum, to meet a range of practical and aesthetic requirements. They also aid in lowering noise levels, increasing seclusion, and improving sound insulation. Partition systems are also perfect for firms that need to adjust to shifting layouts because they are simple to install and modify.`}
imageurl={"/assets/images/commercial_partition_system/Why we use.jpg"}
          />
          <div
            style={{ margin: "auto", padding: "2rem 0", textAlign: "center" }}
          >
            <Heading title={"Benefits of Commercial Partition System"} />
          </div>
          <RightImagesTextcard
            orangeheading={"Cost-Effective"}
            title=" - and Easy Installation"
            content={
              "Partition systems are often quick and simple to install, making them an affordable choice for space management. Their easily assembleable and reconfigurable modular architecture reduces downtime and interference with regular operations."
            }
            imageurl={"assets/images/commercial_partition_system/Benefit section 1.jpg"}
          />
          <LeftImagesTextCard
            orangeheading={"Flexible"}
            title={"Space Management Solutions"}
            content={
              "With the use of commercial divider systems, businesses can simply rearrange spaces for different purposes, such conference rooms or private offices, without having to undertake extensive renovations. This flexibility facilitates dynamic work settings and effectively maximizes the use of available space."
            }
            imageurl={"/assets/images/commercial_partition_system/Benefit section 2.jpg"}
          />
          <RightImagesTextcard
            orangeheading={"Enhanced"}
            title=" - Privacy and Soundproofing"
            content={
              "Excellent sound insulation and privacy are provided by these dividers, which lessen noise distractions and foster a more concentrated work atmosphere. Increased productivity and comfort are facilitated by improved acoustic control, which guarantees the privacy of meetings and conversations."
            }
            imageurl={"/assets/images/commercial_partition_system/Benefit section 3.jpg"}
          />
          <LeftImagesTextCard
            orangeheading={"Aesthetic"}
            title={"and Professional Design"}
            content={
              "Commercial walls are an elegant addition to any office space, and they come in a variety of materials and finishes to choose from. The design will match the logo and aesthetic of your business if there are customizable options."
            }
            imageurl={"/assets/images/commercial_partition_system/Benefit section 4.jpg"}
          />
        </>
      ) : (
        ""
      )}

      <div style={{ height: "3rem" }}></div>
      <Footer />
    </>
  );
};
export { HomeInterior };
