import React from "react";
import { Img } from "../../../subComponents/img";
import { ReadMore } from "../../../CommonComponents/readMore";
import { NavLink } from "react-router-dom";

const ProductOptionCard = () => {
  return (
    <>
      <div className="product_option_card">
        <div className="product_option_image shadow">
          <Img
            src={"assets/images/product/clear_glass.png"}
            alt={"clear_glass.png"}
          />
        </div>
        <div className="product_card_content">
          <p style={{ textAlign: "center", marginBottom: "0px" }}>
            Clear Glass
          </p>
          <p
            style={{
              marginBottom: "5px",
              display: "flex",
              justifyContent: "space-between",
              fontSize:"0.875rem",
            }}
          >
            <span>Dimension</span> <span>160m x 160m</span>
          </p>
          <p
            style={{
              marginBottom: "5px",
              display: "flex",
              justifyContent: "space-between",
               fontSize:"0.875rem"
            }}
          >
            <span>Lorem</span> <span>160m x 160m</span>
          </p>
          <NavLink to={"/lorem"} ><div style={{ width:"max-content", margin:"auto"}}><ReadMore /></div></NavLink>
        </div>
      </div>
    </>
  );
};
export default ProductOptionCard;
