import React, { useEffect } from "react";
import { Heading } from "../../../subComponents/Heading";
import { Text } from "../../../subComponents/text";
import AOS from "aos";
import "aos/dist/aos.css";

const StorySection=()=>{

   useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);

    return(
        <>
          <section className="story_section"  >
             <div className="innerstory_section shadow" >
                <div style={{width:"max-content",margin:"auto"}} ><Heading orangeheading={"Our"} title={"Story"} data-aos="zoom-in" /></div>
                <div className="story_container" style={{overflowX:"hidden"}} >
                    {/* story left container start from here */}
                     <div className="story_left_container" data-aos="fade-right" >
                        <Text style={{fontSize:"1.4rem",lineHeight:"2.5rem", fontWeight:"500", textAlign:"justify"}}
                         text={`At Alu Empire, quality is our hallmark. We are dedicated to producing and supplying products that adhere to the highest standards of craftsmanship and durability. Our commitment to excellence ensures that our clients receive only the best, tailored to meet their specific needs.
Whether you are looking to upgrade your home or design a commercial project, Alu Empire offers unmatched expertise and innovative solutions to bring your vision to life.
`} />
                        <br />
                      <div className="manager_container">
                         <Text text={"Mr. Jatin Gupta"} />
                         <div style={{width:"max-content"}}><Text text={"ALU Empire"} /></div>
                      </div>
                     </div>
                    {/* story left container close from here */}

                    {/* story right container start from here */}
                     <div className="story_right_container" data-aos="fade-left" >
                        <Text text={`We have a dedicated and skilled team of executives who are the cornerstone of our firm's success. Our employees are selected for their expertise, prior experience, and domain proficiency. Working in close collaboration, our team ensures steady organizational growth by leveraging their skills and knowledge. We are rapidly expanding our business across the market and continually adding new clients to our portfolio. To further enhance our team's capabilities, we regularly conduct workshops, seminars, and development programs.`} />
                        <Text text={`Based out of Faridabad, Haryana, Alu Empire is committed to excellence and innovation in the interior products industry.`} />
                        <Text text={` Partner with us for exceptional quality and service in every product we deliver. Our Facilities and Presence`}/>
                        {/* <Text text={"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."} /> */}
                        <br />
                        {/* <Text text={"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."}/> */}
                     </div>
                    {/* story right container close from here */}
                </div>
             </div>
          </section>
        </>
    );
}
export {StorySection};