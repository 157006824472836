import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { baseurl } from "./baseurl";

const SearchBar = () => {
  const [currentFilterproduct, setcurrentFilterproduct] = useState(null);
  const [iskeyword, setIskeyword] = useState("");

  console.log("iskeyword", iskeyword);

  useEffect(() => {
    const fetchFilterproduct = async () => {
      try {
        const formData = new FormData();
        formData.append("keyword", iskeyword);

        const response = await fetch(`${baseurl}/api/search`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data.success === "1") {
          setcurrentFilterproduct(data.search_results); // Access search_results array directly
        } else {
          console.error("Failed to fetch product data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (iskeyword) {
      fetchFilterproduct(); // Only fetch if there's a keyword
    }
  }, [iskeyword]);

  useEffect(() => {
    console.log("Filter products:", currentFilterproduct);
  }, [currentFilterproduct]);

  return (
    <>
    <div>
    <div className="triangle_box"></div>
    <div className="main_searchbar_container">
        <div className="outer_searchbar_container shadow">
          <div className="searchbar_container">
            <div className="search_feild_container">
              <input
                type="search"
                placeholder="Search"
                onChange={(e) => setIskeyword(e.target.value)}
              />
              <Button variant="contained">Search</Button>
            </div>
          </div>
        </div>

        {/* Filter items start from here */}
        {
          iskeyword && 
          <div className="filter_product_container">
          {currentFilterproduct && currentFilterproduct.length > 0 ? (
            currentFilterproduct.map((item, index) => (
              <div key={index}>
                <a
                  href={item.hyperlink}
                  rel="noopener noreferrer"
                  style={{fontWeight:"400", fontSize:"0.785rem"}}
                >
                  {item.title}
                </a>
              </div>
            ))
          ) : (
            <p>No search found</p>
          )}
        </div>
        }
        {/* Filter items close from here */}
      </div>
    </div>
    </>
  );
};

export default SearchBar;
