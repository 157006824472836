import React from "react";

const style = {
    fontSize:"25px",
    color:"#000",
    textTransform:"upperCase"
}

const Heading=({title,className,orangeheading,content,...restProps})=>{
    return(
         <>
           <h1 style={style} className={className} {...restProps} >
            <span style={{color:"#FA853D"}}>{orangeheading}</span> {title}
            <div dangerouslySetInnerHTML={{ __html: content }} />
            </h1>
         </>
    )
}
export {Heading};

