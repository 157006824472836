import React, { useEffect, useState } from "react";
import { Heading } from "../../../../subComponents/Heading";
import { Clientcard } from "./clientCard";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Skeleton from '@mui/material/Skeleton';


// import './styles.css';

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { Subheading } from "../../../../subComponents/subHeading";
import { baseurl } from "../../../../CommonComponents/baseurl";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const OurClients = () => {
  const [currentsreviews, setReviews] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`${baseurl}/api/review-list`, {
          method: "GET",
        });

        const data = await response.json();
        if (data.success === "1") {
          setReviews(data.data);
        } else {
          console.error("Failed to fetch clients reviews data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductDetails();
  }, []);

  return (
    <>
      <section className="our_clients_section" id='testimonial' >
        <div className="our_clients_container">
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Heading title={"OUR HAPPY"} style={{ color: "#fff" }} />
            <Heading title={"CLIENTS"} />
          </div>
        </div>
        <div className="our_clients_card_container">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop="true"
            breakpoints={{
              200: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              546: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper client_slider"
          >
            {currentsreviews === null ? 
            <div>
          <Skeleton variant="rectangular" width="100%" height={"100%"}>
          <div style={{ paddingTop: '27%' }} />
        </Skeleton>
        <br />
         <Skeleton variant="rectangular" width={"100%"} height={"20px"} />
         <Skeleton variant="rectangular" width={"100%"} height={"20px"} style={{marginTop:'10px'}} />

            </div>

        
            : (
              currentsreviews.map((items) => {
                return (
                  <>
                    <SwiperSlide>
                      <Clientcard
                        pharagraph={items.description}
                        clientname={items.client_name}
                        designation={items.designation}
                        imageurl={items.image}
                      />
                    </SwiperSlide>
                  </>
                );
              })
            )}
          </Swiper>
        </div>
      </section>
    </>
  );
};
export { OurClients };
