import React, { useEffect, useState } from "react";
import Header from "../../CommonComponents/header";
import HeroSection from "./Components/HeroSetion";
import { AboutSection } from "./Components/aboutSection";
import { ServiceSection } from "./Components/SERVICESETION/serviceSection";
import { Heading } from "../../subComponents/Heading";
import { Subheading } from "../../subComponents/subHeading";
import { Img } from "../../subComponents/img";
import { RecentProjectSection } from "./Components/RECENT_PROJECT/recentProjectSection";
import { Countersection } from "./Components/COUNTERSECTION/counterSection";
import { Blogs } from "./Components/BLOGS/blogs";
import { OurClients } from "./Components/OURCLIENTS/ourClientsSection";
import { Footer } from "../../CommonComponents/footer";
import { MobileHeader } from "../../CommonComponents/mobileHeader";
import { useParams } from "react-router-dom";
import { baseurl } from "../../CommonComponents/baseurl";
// import AOS from "aos";
// import "aos/dist/aos.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';


function HomePage() {
  const [currentbanner, setCurrentBanner] = useState("");

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const formData = new FormData();
        formData.append("title", "Home");

        const response = await fetch(`${baseurl}/api/banner`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data.success === "1") {
          setCurrentBanner(data.data);
        } else {
          console.error("Failed to currentbanner data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBanner();
  }, []);

  useEffect(() => {
    console.log("currentbanner:", currentbanner);
  }, [currentbanner]);

  return (
    <>
      <Header />
      <MobileHeader />
      <div className="mobile_header_spacer_home"></div>
      <div className="desktop_header_spacer"></div>
      {/* hero section start from here */}
      <HeroSection />
      {/* hero section close from here */}

      {/* about us section start from here */}
      <AboutSection />
      {/* about us section close from here */}

      {/* service section start from here */}
      <ServiceSection />
      {/* service section close from here */}

      {/* new launch section start from here */}
      <section className="new_launch_section" data-aos="fade-up">
        <div style={{ width: "100%", textAlign: "center" }}>
          {/* <Subheading
            title={
              currentbanner === null ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress style={{ color: "#000", margin: "auto" }}  />
                </Box>
              ) : (
                currentbanner.title
              )
            }
            style={{ fontSize: "1rem", color: "#FA853D" }}
          /> */}

<Subheading
  title={
    currentbanner === null ? (
      <Box sx={{ display: "flex" }}>
        <CircularProgress style={{ color: "#000", margin: "auto" }} />
      </Box>
    ) : (
      <span
        dangerouslySetInnerHTML={{ __html: currentbanner.title }}
        style={{ fontSize: "1rem", color: "#FA853D" }}
      />
    )
  }
/>

        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Heading
            title={
              currentbanner === null ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress style={{ color: "#000", margin: "auto" }} />
                </Box>
              ) : (
                currentbanner.description
              )
            }
          />
        </div>
        <div className="new_launch_section_image">
          {currentbanner.image === null?
                            <Skeleton variant="rectangular" width="100%" height={"100%"}>
                            <div style={{ paddingTop: '57%' }} />
                          </Skeleton>
                          :
                          <Img
                          src={
                            currentbanner == null ? (
                              ''
                            ) : (
                              currentbanner.image
                            )
                          }
                          alt={"cubical shower"}
                        />
          }

        </div>
      </section>
      {/* new launch section close from here */}

      {/* recent project section start here */}
      <RecentProjectSection />
      {/* recent [project clo9se from here] */}
      <br />

      {/* counter section start from here */}
      <Countersection />
      {/* counter section close from here */}

      {/* trust section start form here */}
      <section className="trust_section">
        <Heading
          title={"TRUST THE EXPERTS FOR ALL "}
          style={{ color: "#fff" }}
          data-aos="zoom-in"
        />
        <Subheading
          title={"“YOUR BUILDING NEED” "}
          style={{ fontWeight: "400", marginTop: "1rem" }}
          data-aos="zoom-in"
        />
      </section>
      {/* trust section close from here */}

      {/* blog section start from here */}
      <Blogs />
      {/* blog section closse from here */}

      {/* our clients section start from here */}
      <OurClients />
      {/* our clients section close from here */}

      {/* footer start from here */}
      <Footer />
      {/* footer close from here */}
      {/* <div style={{marginTop:"100px",backgroundColor:"red"}}></div> */}
    </>
  );
}
export default HomePage;
