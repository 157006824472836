import React from "react";
import { Img } from "../../../subComponents/img";
import { Heading } from "../../../subComponents/Heading";
import { Text } from "../../../subComponents/text";

const BlogHeroSection=({pagetitle,pagedescription})=>{
    return(
        <>
         <section className="blog_hero_section" >
           <div className="blog_hero_banner"><Img src={"assets/images/Blog/blog.png"} alt={"blog.png"} /></div>
           <div className="blog_hero_banner_overlay">
            <div className="innerblog_hero_content">
               <Heading title={pagetitle} />
               <Text text={pagedescription} />
            </div>
           </div>
         </section>
        </>
    );
}
export {BlogHeroSection};