import {React,useEffect} from "react";
import { Img } from "../../../subComponents/img";
import { Text } from "../../../subComponents/text";
import { ReadMore } from "../../../CommonComponents/readMore";
import AOS from "aos";
import "aos/dist/aos.css";

const BlogCard=({title,discription,blogtime,imageurl})=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    

    return(
        <>
        <div className="blog_page_card shadow" 
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000" 
         >
           <div className="blog_image_container" ><Img src={imageurl} alt={"blog card.png"} /></div>
           <div className="blog_image_contents">
              <Text className={"blog_card_title "} style={{}} text={title} />
              <Text style={{fontSize:"0.8rem",marginBottom:"0.5rem"}} text={blogtime} />
              <p className={"blog_card_description"} dangerouslySetInnerHTML={{ __html: discription }}></p>
              {/* <Text className={"blog_card_description"} style={{fontSize:"0.8rem",marginBottom:"0.5rem"}}  text={discription} /> */}
              <ReadMore style={{marginTop:"1rem"}} />
           </div>
        </div>
        </>
    );
}
export {BlogCard};