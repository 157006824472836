import React, { useEffect, useState } from "react";
import { Text } from "../subComponents/text";
import { Img } from "../subComponents/img";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Modal from "@mui/material/Modal";
import { baseurl } from "./baseurl";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "2rem",
  boxShadow: 24,
  // p: 4,
};

const Footer = () => {
  const [openmodal, setOpenmodal] = React.useState(false);
  const handleClosemodal = () => setOpenmodal(false);

  const handleOpen = () => setOpenmodal(true);

  const [openmodal2, setOpenmodal2] = React.useState(false);
  const handleClosemodal2 = () => setOpenmodal2(false);

  const handleOpen2 = () => setOpenmodal2(true);

  const [customer_name, setCustomerName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [required_service, setRequiredService] = useState("uPVC");
  const [otherdescription, setotherDescription] = useState("");

  const [successmessege, setSuccessmessege] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const validateForm = () => {
    if (!customer_name) {
      setError("Name is required");
      return false;
    }
    if (!phone_number) {
      setError("Phone number is required");
      return false;
    }
    if (!email) {
      setError("Email is required");
      return false;
    }
    if (required_service === "other" && !otherdescription) {
      setError("Please specify the service");
      return false;
    }
    setError(""); // Clear any previous error
    return true;
  };

  const formSubmit = async () => {
    if (!validateForm()) {
      handleClick();
      return;
    }

    try {
      const response = await fetch(`${baseurl}/api/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_name,
          phone_number,
          email,
          required_service,
          otherdescription,
        }),
      });

      const data = await response.json();
      if (data.success === "1") {
        console.log(data.message);
        setSuccessmessege(data.message);
        handleClick();
      } else {
        console.error("Failed to submit data:", data.message);
        setError(data.message);
        handleClick();
        handleClosemodal();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setError("An error occurred. Please try again.");
      handleClick();
    }
  };

  useEffect(() => {
    console.log("required_service:", required_service);
  }, [required_service]);

  return (
    <>
      <div className="outer_footer_section">
        <section className="footer_section">
          {/* footer social media start from here */}
          <div className="footer_top_section">
            <Link to={"/"}>
              <div className="footer_logo">
                <Img src={"/assets/images/white logo.png"} alt={"logo alu empire"} />
              </div>
            </Link>

            <div className="footer_social_media_container">
              <h5>Connect With Us</h5>
              <div className="footer_social_medias">
                <Link to={"https://www.linkedin.com/company/alu-empire/"} target="blank">
                  <div className="social_media">
                    <Img
                      src={"/assets/images/Home/icons/linkedin.png"}
                      alt={"linkedin"}
                    />
                  </div>
                </Link>
                <Link to={"#"} target="blank">
                  <div className="social_media">
                    <Img
                      src={"/assets/images/Home/icons/facebook.png"}
                      alt={"facebook"}
                    />
                  </div>
                </Link>
                <a href="https://wa.me/919002690068?text=" target="_blank" rel="noreferrer">
                  <div className="social_media">
                    <Img
                      src={"/assets/images/Home/icons/whatsapp.png"}
                      alt={"whatsapp"}
                    />
                  </div>
                </a>
                <Link to={"https://www.instagram.com/aluempire"} target="blank">
                  <div className="social_media">
                    <Img
                      src={"/assets/images/Home/icons/insta.png"}
                      alt={"instagram"}
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {/* footer social medai close from here */}

          {/* footer navigation start from here */}
          <div className="footer_navigation_container">
            {/* footer card one start from here */}
            <div className="footer_card">
              <div>
                <div className="first_link_footer">
                  <div>
                    <Text
                      text={"Discover AluEmpire"}
                      className={"footer_heading"}
                    />
                    <NavLink to={"/"} style={{ textDecoration: "none" }}>
                      <Text text={"Home"} />
                    </NavLink>
                    <NavLink
                      to={"/about-us"}
                      style={{ textDecoration: "none" }}
                    >
                      <Text text={"About Us"} />
                    </NavLink>
                    <NavLink to={"/blogs"} style={{ textDecoration: "none" }}>
                      {" "}
                      <Text text={"Blog"} />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            {/* footer card one close from here */}

            {/* footer card two start from here */}
            <div className="footer_card">
              <div>
                <Text text={"Alu Home Interior"} className={"footer_heading"} />
                <a
                  // to={"/alu-home-interior/doors-and-window"}
                  href="/alu-home-interior/doors-and-window"
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Door & Window"} />
                </a>
                <a
                  href={"/alu-home-interior/stair-railing"}
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Stair Railing"} />
                </a>
                <a
                  href={"/alu-home-interior/shower-cubical"}
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Shower Cubical"} />
                </a>
                <a
                  href={"/alu-home-interior/sky-light"}
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <Text text={"Sky Light"} />
                </a>
              </div>
            </div>
            {/* footer card two close from here */}

            {/* footer card three start from here */}
            <div className="footer_card">
              <div>
                <Text
                  text={"uPVC Window & Door"}
                  className={"footer_heading"}
                />
                <a
                  href={"/upvc-door-and-window/window"}
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Window"} />
                </a>
                <a
                  href={"/upvc-door-and-window/door"}
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Door"} />
                </a>
                <NavLink
                  to={"/accessories/window-door-and-accessories"}
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Accessories"} />
                </NavLink>
              </div>
            </div>
            {/* footer card three close from here */}

            {/* footer card four start from here */}
            <div className="footer_card">
              <div>
                <Text
                  text={"Commercial Partition System"}
                  className={"footer_heading"}
                />
                <a
                  href={"/commercial-partition-system/glass-partition"}
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Glass Partition"} />
                </a>
                <a
                  href={"/accessories/profile-and-accessories"}
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Profile & Accessories"} />
                </a>
              </div>
            </div>
            {/* footer card four close from here */}

            {/* footer card five start from here */}
            <div className="footer_card">
              <div>
                <Text text={"Support"} className={"footer_heading"} />
                <NavLink
                  to={"/terms-&-Condition"}
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Terms & Condition"} />
                </NavLink>
                <NavLink
                  to={"/privacy-policy"}
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Privacy Policy"} />
                </NavLink>
                <NavLink to={"/locate-us"} style={{ textDecoration: "none" }}>
                  <Text text={"Locate Us"} />
                </NavLink>
              </div>
            </div>
            {/* footer card five close from here */}

            {/* footer card Six start from here */}
            {/* <div className="footer_card">
              <div>
                <Text text={"Contact Us"} className={"footer_heading"} />
                <div className="links">
                  <Text
                    text={"Reach the business head"}
                    onClick={handleOpen2}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="links">
                  <Text
                    text={"Customer Complaint"}
                    onClick={handleOpen}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <HashLink
                  to={"/Contact-us#quiery-form"}
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Submit Enquiry"} />
                </HashLink>
                <Link
                  to="javascript:void(0)"
                  onClick={() =>
                    (window.location = "mailto:yourmail@domain.com")
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Text text={"Email Us"} />
                </Link>
              </div>
            </div> */}
            {/* footer card four Six from here */}
          </div>
          {/* footer navigation close from here */}
        </section>

        {/* footer copyright text start from */}
        <h6
          style={{
            textAlign: "center",
            fontSize: "0.75rem",
            padding: "1.4rem",
            backgroundColor: "#000",
            color: "#fff",
            borderTop: "2px solid rgba(255, 255, 255, 0.075)",
          }}
        >
          Copyright 2024 - Alponix private Limited - All Right Reserved.
        </h6>
        {/* footer copyright text close from */}
      </div>


{/* modal 1 start from here */}
      <Modal
        open={openmodal}
        onClose={handleClosemodal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="innergetin_touch_right_container shadow">
            <Text
              style={{ color: "#fff", fontSize: "1.3rem" }}
              text={"CUSTOMER COMPLAINTS"}
            />
            <div className="form_input_feild_container">
              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>Name</p>
                </div>
                <div className="input_feild">
                  <input
                    className="contact_form_input_feild"
                    type="text"
                    placeholder="Enter your name"
                    required
                    onChange={(e) => setCustomerName(e.target.value)}
                  />
                </div>
              </div>
              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>
                    Phone Number
                  </p>
                </div>
                <div className="input_feild">
                  <input
                    className="contact_form_input_feild"
                    type="text"
                    placeholder="Enter your phone number"
                    required
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>
                    I’m looking for
                  </p>
                </div>
                <div className="input_feild">
                  <select
                    name="requiredService"
                    id="requiredService"
                    required
                    onChange={(e) => setRequiredService(e.target.value)}
                  >
                    <option value="uPVC">uPVC</option>
                    <option value="ALU Home Interior">ALU Home Interior</option>
                    <option value="Commercial partition system">
                      Commercial partition system
                    </option>
                    <option value="other">other</option>
                  </select>
                </div>
              </div>

              {required_service === "other" ? (
                <div className="pricing_container">
                  <div className="input_feild_title">
                    <p style={{ marginBottom: "0", color: "#fff" }}></p>
                  </div>
                  <div className="input_feild">
                    <input
                      className="contact_form_input_feild"
                      type="text"
                      placeholder="Please specify"
                      required
                      onChange={(e) => setotherDescription(e.target.value)}
                    />
                  </div>
                </div>
              ) : null}

              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>Email ID</p>
                </div>
                <div className="input_feild">
                  <input
                    type="email"
                    className="contact_form_input_feild"
                    placeholder="Enter your email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <button className="form_btn" onClick={formSubmit}>
                Submit
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 15 15"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    d="m13.5 7.5l-4-4m4 4l-4 4m4-4H1"
                  />
                </svg>
              </button>

              {error && (
                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  message={error}
                />
              )}
              {successmessege && (
                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  message={successmessege}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
{/* modal 1 close from here */}


{/* modal 2 start from here */}
      <Modal
        open={openmodal2}
        onClose={handleClosemodal2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="innergetin_touch_right_container shadow">
            <Text
              style={{ color: "#fff", fontSize: "1.3rem" }}
              text={"Reach The Business Head"}
            />
            <div className="form_input_feild_container">
              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>Name</p>
                </div>
                <div className="input_feild">
                  <input
                    className="contact_form_input_feild"
                    type="text"
                    placeholder="Enter your name"
                    required
                    onChange={(e) => setCustomerName(e.target.value)}
                  />
                </div>
              </div>
              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>
                    Phone Number
                  </p>
                </div>
                <div className="input_feild">
                  <input
                    className="contact_form_input_feild"
                    type="text"
                    placeholder="Enter your phone number"
                    required
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>
                    I’m looking for
                  </p>
                </div>
                <div className="input_feild">
                  <select
                    name="requiredService"
                    id="requiredService"
                    required
                    onChange={(e) => setRequiredService(e.target.value)}
                  >
                    <option value="uPVC">uPVC</option>
                    <option value="ALU Home Interior">ALU Home Interior</option>
                    <option value="Commercial partition system">
                      Commercial partition system
                    </option>
                    <option value="other">other</option>
                  </select>
                </div>
              </div>

              {required_service === "other" ? (
                <div className="pricing_container">
                  <div className="input_feild_title">
                    <p style={{ marginBottom: "0", color: "#fff" }}></p>
                  </div>
                  <div className="input_feild">
                    <input
                      className="contact_form_input_feild"
                      type="text"
                      placeholder="Please specify"
                      required
                      onChange={(e) => setotherDescription(e.target.value)}
                    />
                  </div>
                </div>
              ) : null}

              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>Email ID</p>
                </div>
                <div className="input_feild">
                  <input
                    type="email"
                    className="contact_form_input_feild"
                    placeholder="Enter your email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <button className="form_btn" onClick={formSubmit}>
                Submit
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 15 15"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    d="m13.5 7.5l-4-4m4 4l-4 4m4-4H1"
                  />
                </svg>
              </button>

              {error && (
                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  message={error}
                />
              )}
              {successmessege && (
                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  message={successmessege}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
{/* modal 2 close from here */}
    </>
  );
};
export { Footer };
