import React, { useEffect, useState } from "react";
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Box, Modal, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import Header from "../../../../CommonComponents/header";
import { Footer } from "../../../../CommonComponents/footer";
import FilterOptionButton from "../../Components/filterOptionButton";
import { Heading } from "../../../../subComponents/Heading";
import { MobileHeader } from "../../../../CommonComponents/mobileHeader";
import { baseurl } from "../../../../CommonComponents/baseurl";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AccessoriesShopPage = () => {
  const [currentAccessories, setAccessories] = useState([]);
  const [selectedAccessory, setSelectedAccessory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState();
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [accessoryslug, setAccessoryslug] = useState(null);
  const [open, setOpen] = useState(false);

  const { slug } = useParams();

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const formData = new FormData();
        formData.append("slug", slug);
        formData.append("slug_type", "product_slug");

        const response = await fetch(`${baseurl}/api/accessory-category`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data.success === "1") {
          setAccessories(data.data);
          const initialCategory =
            data.data.find((category) => category.slug === slug) ||
            data.data[0];
          setSelectedCategory(initialCategory);
          setAccessoryslug(initialCategory.slug);
        } else {
          console.error(
            "Failed to fetch accessory category data:",
            data.message
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductDetails();
  }, [slug]);

  useEffect(() => {
    fetchBlogs(currentPage, accessoryslug);
  }, [currentPage, accessoryslug]);

  const fetchBlogs = async (page, accessoryslug) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("product_page", page);
      formData.append("slug", accessoryslug);

      const response = await fetch(`${baseurl}/api/accessory`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.success === "1") {
        if (data.has_sub_categories === true) {
          setBlogs([]);
          // setSelectedCategory(null);
          setShowMore(false);
        } else {
          if (page === 1) {
            setBlogs(data.data);
          } else {
            setBlogs((prevBlogs) => [...prevBlogs, ...data.data]);
          }
          setTotalPages(data.pagination_data.total_pages);
          setShowMore(page < data.pagination_data.total_pages);
        }
      } else {
        console.error("Failed to fetch blog data:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = (accessory) => {
    setSelectedAccessory(accessory);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAccessory(null);
  };

  const handleFilterClick = (category) => {
    setSelectedCategory(category);
    setAccessoryslug(category.slug);
    setBlogs([]);
    setCurrentPage(1);
    setShowMore(true);
  };

  const resetToInitialPage = () => {
    setBlogs([]);
    setCurrentPage(1);
    setShowMore(true);
  };

  const renderAccessories = () => {
    if (selectedCategory && !selectedCategory.has_sub_categories) {
      return (
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {blogs.map((item) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              key={item.id}
              onClick={() => handleOpen(item)}
            >
              <Card sx={{ maxWidth: 300, maxHeight: "300px", minHeight: "230px", mx: "auto" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    width="300"
                    image={item.image}
                    alt={item.title}
                    style={{ objectFit: "contain" }}
                  />
                </CardActionArea>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className="accessories_title"
                    style={{ fontSize: "1rem", fontWeight: "600" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="accessories_description"
                    style={{ color: "rgba(0, 0, 0, 0.6)", fontFamily: "sans-serif" }}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  ></Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      );
    } else if (selectedCategory && selectedCategory.has_sub_categories) {
      return selectedCategory.sub_categories.map((subCategory) => (
        <div key={subCategory.sub_category}>
          <Heading title={subCategory.sub_category} />
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {subCategory.data.map((item) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                key={item.id}
                onClick={() => handleOpen(item)}
              >
                <Card sx={{ maxWidth: 300, maxHeight: "300px", minHeight: "230px", mx: "auto" }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      width="300"
                      image={item.image}
                      alt={item.title}
                      style={{ objectFit: "contain" }}
                    />
                  </CardActionArea>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      className="accessories_title"
                      style={{ fontSize: "1rem", fontWeight: "600" }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="accessories_description"
                      style={{ color: "rgba(0, 0, 0, 0.6)", fontFamily: "sans-serif" }}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      ));
    }
  };

console.log(selectedCategory)
  
  return (
    <>
      <Header />
      <MobileHeader />
      <div className="desktop_header_spacer"></div>
      <section className="accessories_main_section">
        <div className="accessories_filter_btn_container">
          {currentAccessories.length === 0 ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress style={{ color: "#fff", margin: "auto" }} />
            </Box>
          ) : (
            currentAccessories.map((item) => (
              <FilterOptionButton
                key={item.id}
                text={item.title}
                iconImage={item.icon_image}
                onClick={() => handleFilterClick(item)}
                style={{
                  backgroundColor:
                    item.slug === selectedCategory?.slug
                      ? "#FA853D"
                      : "#E9E9E9",
                  color: item.slug === selectedCategory?.slug ? "#fff" : "#000",
                }}
              />
            ))
          )}
        </div>

        <Heading title={selectedCategory ? selectedCategory.title : ""} />
        <br />

        <Box sx={{ width: { xs: "100%", sm: "90%", md: "90%" }, mx: "auto" }}>
          {renderAccessories()}
        </Box>

        <div className="load-more-container">
          {showMore && (
            <button
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={isLoading}
              className="loadmorebtn"
            >
              {isLoading ? "Loading..." : "Show More"}
            </button>
          )}
          {!showMore && blogs.length > 0 && currentPage > 1 && (
            <button onClick={resetToInitialPage} className="loadmorebtn">
              Show Less
            </button>
          )}
        </div>
      </section>




      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={style}>
        <button onClick={handleClose} className="modal_close_button">
        X
       </button>
          {selectedAccessory && (
            <>
              <Typography id="modal-title" variant="h6" component="h2">
                {selectedAccessory.title}
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: selectedAccessory.description}} >
              </Typography>
              <img
                src={selectedAccessory.image}
                alt={selectedAccessory.title}
                style={{ width: "100%", height: "auto", marginTop: "10px" }}
              />
            </>
          )}
        </Box>
      </Modal>

      <Footer />
    </>
  );
};

export default AccessoriesShopPage;



