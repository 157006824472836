import React, { useEffect } from "react";
import { Img } from "../../../subComponents/img";
import { Subheading } from "../../../subComponents/subHeading";
import AOS from "aos";
import "aos/dist/aos.css";

const WhyProductCategorySection = ({title,content,imageurl}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <section className="upvc_window_section">
        <div className="upvc_window_container" style={{overflowX:"hidden"}} >
          {/* upvc_window_rightcontainer start from here */}
          <div className="upvc_window_rightcontainer" data-aos="fade-right" >
            <Subheading
              style={{ marginTop: "1rem" }}
              title={title}
            />
            <p style={{ margin: "1rem 0" }}>
                {content}
            </p>
          </div>
          {/* upvc_window_rightcontainer  close from here*/}


          {/* upvc_window_leftcontainer start from here */}
          <div className="upvc_window_leftcontainer">
            <div className="innerupvc_window_leftcontainer" data-aos="fade-left" >
            <Img
              src={imageurl}
              alt={"window.png"}
              style={{ marginLeft: "150px" }}
            />
            </div>
          </div>
          {/* upvc_window_leftcontainer close from here */}
        </div>
      </section>
    </>
  );
};
export { WhyProductCategorySection };
