import React, { useEffect } from "react";
import { Heading } from "../../../subComponents/Heading";
import { VisionCard } from "./subComponents/visionCard";
import { ContentCard } from "./subComponents/contentCard";
import AOS from "aos";
import "aos/dist/aos.css";

const VisionMissionSection=()=>{

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return(
        <>
         <section className="vision_mission_section"  >
            {/* <Heading orangeheading={"VISION AND MISSION"} title={``} data-aos="zoom-in" /> */}
            <section className="vision_section">
            <Heading orangeheading={"VISION AND MISSION"} title={``} data-aos="zoom-in" style={{textAlign:"center",marginTop:"1rem"}} />
                <div className="vision_container" style={{overflowX:"hidden"}} >
                    <VisionCard title={"Vision"} />
                    <ContentCard title={"Designed By Architects"} content={`Our vision is to revolutionize the interior design industry by delivering innovative and high-quality products that transform spaces and enhance living experiences. We are committed to touching the milestone of 1 Billion in revenue within the next two years, driving substantial growth and creating significant employment opportunities. By fostering a culture of excellence, sustainability, and customer-centricity, we aim to become a leading name in the interior products market, empowering our team and community to thrive in an ever-evolving landscape.`} />
                </div>
            </section>

            <section className="mission_section">
                <div className="vision_container mission_container" style={{overflowX:"hidden"}}>
                    <ContentCard title={"Designed By Architects"} content={"To create a service company that is passionate about offering Exceptional Customer Experience and Customized Building Solutions."} />
                    <VisionCard title={"Mission"} />
                </div>
            </section>
         </section>
        </>
    );
}
export {VisionMissionSection};