import React from "react";
import { Img } from "../../../subComponents/img";

const FilterOptionButton = ({ text, iconImage, ...restprops }) => {
  return (
    <>
      <div className="filter_option_button" {...restprops}>
        {iconImage === null ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1rem"
            height="1rem"
            viewBox="0 0 256 256"
          >
            <path
              fill="currentColor"
              d="M232 216h-24V40a16 16 0 0 0-16-16H64a16 16 0 0 0-16 16v176H24a8 8 0 0 0 0 16h208a8 8 0 0 0 0-16M64 40h128v176H64Zm104 92a12 12 0 1 1-12-12a12 12 0 0 1 12 12"
            />
          </svg>
        ) : (
          <div>
            <Img src={iconImage} alt={iconImage} />
          </div>
        )}
        <div style={{ textTransform: "uppercase" }} dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
    </>
  );
};
export default FilterOptionButton;
