// import React, { useEffect, useState } from "react";
// import Header from "../../CommonComponents/header";
// import { Footer } from "../../CommonComponents/footer";
// import { BlogHeroSection } from "./Components/blogHeroSection";
// import { Heading } from "../../subComponents/Heading";
// import { BlogCard } from "./Components/blogCard";
// import { MobileHeader } from "../../CommonComponents/mobileHeader";
// import { NavLink } from "react-router-dom";
// import { baseurl } from "../../CommonComponents/baseurl";

// function BlogsPage() {

//   const [blogs, setBlogs] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [ishide, setIshide] = useState(true);
//   const [isnextbtnhide, setIsnextbtnhide] = useState(false);

//   useEffect(() => {
//     const fetchBlogs = async () => {
//         try {
//             const formData = new FormData();
//             formData.append('blog_page', currentPage);

//             const response = await fetch(`${baseurl}/api/blog-list`, {
//                 method: 'POST',
//                 body: formData
//             });

//             const data = await response.json();
//             if (data.success === "1") {
//                 setBlogs(data.data);
//                 setTotalPages(data.pagination_data.total_pages); 
//             } else {
//                 console.error('Failed to fetch blog data:', data.message);
//             }
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     fetchBlogs();
// }, [currentPage]);




// function formatDate(dateString) {
//   const date = new Date(dateString);
//   const day = String(date.getDate()).padStart(2, '0');
//   const month = String(date.getMonth() + 1).padStart(2, '0'); 
//   const year = date.getFullYear();
//   return `${day}/${month}/${year}`;
// }
 

//     return(
//       <>
//         <Header />
//         <MobileHeader />
//         <BlogHeroSection
//           pagetitle={"BLOGS"}
//           pagedescription={
//             "Lorem ipsum dolor sit amet, consectetur adipiscing"
//           }
//         />
//         <section className="blogs_section">
//           <Heading style={{ padding: "1rem 0" }} title={"Recent Blogs"} />

          
//           <div className="blogs_container">
//             {
//               blogs.map((items)=>{
//                return(
//                 <NavLink to={`/blog-details-page/${items.slug}`}>
//                 <BlogCard
//                   title={items.title}
//                   discription={items.description}
//                   blogtime={formatDate(items.datetime)}
//                   imageurl={items.image}
//                 />
//               </NavLink>
//                )
//               })
//             }

//           </div>

//         </section>
//         <Footer />
//       </>
//     );
// }
// export default BlogsPage;


// import React, { useEffect, useState } from "react";
// import Header from "../../CommonComponents/header";
// import { Footer } from "../../CommonComponents/footer";
// import { BlogHeroSection } from "./Components/blogHeroSection";
// import { Heading } from "../../subComponents/Heading";
// import { BlogCard } from "./Components/blogCard";
// import { MobileHeader } from "../../CommonComponents/mobileHeader";
// import { NavLink } from "react-router-dom";
// import { baseurl } from "../../CommonComponents/baseurl";
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';

// function BlogsPage() {
//   const [blogs, setBlogs] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       try {
//         const formData = new FormData();
//         formData.append('blog_page', currentPage);

//         const response = await fetch(`${baseurl}/api/blog-list`, {
//           method: 'POST',
//           body: formData
//         });

//         const data = await response.json();
//         if (data.success === "1") {
//           setBlogs(data.data);
//           setTotalPages(data.pagination_data.total_pages); 
//         } else {
//           console.error('Failed to fetch blog data:', data.message);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchBlogs();
//   }, [currentPage]);

//   function formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, '0');
//     const month = String(date.getMonth() + 1).padStart(2, '0'); 
//     const year = date.getFullYear();
//     return `${day}/${month}/${year}`;
//   }

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   return (
//     <>
//       <Header />
//       <MobileHeader />
//       <BlogHeroSection
//         pagetitle={"BLOGS"}
//         pagedescription={"Lorem ipsum dolor sit amet, consectetur adipiscing"}
//       />
//       <section className="blogs_section">
//         <Heading style={{ padding: "1rem 0" }} title={"Recent Blogs"} />
//         <div className="blogs_container">
//           {blogs.map((items) => (
//             <NavLink to={`/blog-details-page/${items.slug}`} key={items.slug}>
//               <BlogCard
//                 title={items.title}
//                 discription={items.description}
//                 blogtime={formatDate(items.datetime)}
//                 imageurl={items.image}
//               />
//             </NavLink>
//           ))}
//         </div>
//         <Stack spacing={2} style={{ marginTop: '2rem', alignItems: 'center' }}>
//           <Pagination
//             count={totalPages}
//             page={currentPage}
//             onChange={handlePageChange}
//             sx={{
//               '& .MuiPaginationItem-root': {
//                 backgroundColor: '#fff',
//                 color: '#000',
//                 '&:hover': {
//                   backgroundColor: '#FA853D',
//                 },
//                 '&.Mui-selected': {
//                   backgroundColor: '#FA853D',
//                   color: '#fff',
//                   '&:hover': {
//                     backgroundColor: '#FA853D',
//                   },
//                 },
//               },
//             }}
//           />
//         </Stack>
//       </section>
//       <Footer />
//     </>
//   );
// }

// export default BlogsPage;








import React, { useEffect, useState } from "react";
import Header from "../../CommonComponents/header";
import { Footer } from "../../CommonComponents/footer";
import { BlogHeroSection } from "./Components/blogHeroSection";
import { Heading } from "../../subComponents/Heading";
import { BlogCard } from "./Components/blogCard";
import { MobileHeader } from "../../CommonComponents/mobileHeader";
import { NavLink } from "react-router-dom";
import { baseurl } from "../../CommonComponents/baseurl";

function BlogsPage() {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    fetchBlogs(currentPage);
  }, [currentPage]);

  const fetchBlogs = async (page) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('blog_page', page);

      const response = await fetch(`${baseurl}/api/blog-list`, {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      if (data.success === "1") {
        setBlogs((prevBlogs) => [...prevBlogs, ...data.data]);
        setTotalPages(data.pagination_data.total_pages);
        if (page >= data.pagination_data.total_pages) {
          setShowMore(false); 
        }
      } else {
        console.error('Failed to fetch blog data:', data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreBlogs = () => {
    if (showMore) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <Header />
      <MobileHeader />
      <div className="desktop_header_spacer"></div>
      <BlogHeroSection
        pagetitle={"BLOGS"}
        pagedescription={
          "Explore our world of innovation, creativity, adventure, and endless possibilities"
        }
      />
      <section className="blogs_section">
        <Heading style={{ paddingBottom: "2rem", textalign:"center" }} title={"Recent Blogs"} />
        <div className="blogs_container">
          {
            blogs.map((items, index) => (
              <NavLink key={index} to={`/blog-details-page/${items.slug}`}>
                <BlogCard
                  title={items.title}
                  discription={items.description}
                  blogtime={formatDate(items.datetime)}
                  imageurl={items.image}
                />
              </NavLink>
            ))
          }
        </div>
        <div className="load-more-container">
          {showMore && (
            <button onClick={loadMoreBlogs} disabled={isLoading} className="loadmorebtn" >
              {isLoading ? 'Loading...' : 'Show More'}
            </button>
          )}
          {!showMore && (
            <button onClick={() => setBlogs([]) & setCurrentPage(1) & setShowMore(true)} className="loadmorebtn" >
              Show Less
            </button>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default BlogsPage;
