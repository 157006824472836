import React, { useEffect, useState } from "react";
import { Heading } from "../../../../subComponents/Heading";
import { Img } from "../../../../subComponents/img";
import { ReadMore } from "../../../../CommonComponents/readMore";
import { Subheading } from "../../../../subComponents/subHeading";

import AOS from "aos";
import "aos/dist/aos.css";
import { baseurl } from "../../../../CommonComponents/baseurl";
import { NavLink } from "react-router-dom";
import zIndex from "@mui/material/styles/zIndex";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';


const RecentProjectSection = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [currentbanner, setCurrentBanner] = useState(null);
  

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await fetch(`${baseurl}/api/our-projects`, {
          method: "GET",
        });

        const data = await response.json();
        if (data.success === "1") {
          setCurrentBanner(data.data);
        } else {
          console.error("Failed to currentbanner data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBanner();
  }, []);

  useEffect(() => {
    console.log("currentbanner:", currentbanner);
  }, [currentbanner]);

  return (
    <>
      <section className="recent_project_section" id="recentproject">
        <div style={{ textAlign: "center" }}>
          <Subheading
            title={"Transform your space, transform your life."}
            style={{
              color: "#FA853D",
              fontSize: "1rem",
              textTransform: "uppercase",
            }}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Heading title={"RECENT PROJECT"} />
        </div>

        <div className="recent_project_container">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            cssMode={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 1500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            loop="true"
            breakpoints={{
              200: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
              1524: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {currentbanner === null ?
          <div className="skeleton_blog_container" style={{width:"100%"}}>
          <Skeleton variant="rectangular" width="32%" height={"350px"}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
        <Skeleton variant="rectangular" width="32%" height={"350px"}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
        <Skeleton variant="rectangular" width="32%" height={"350px"}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
          </div>: (
              currentbanner.map((items) => {
                return (
                  <>
                    <SwiperSlide>
                      <div
                        className="recent_project"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                      >
                        <div className="home_recent_project_image">
                        <Img src={items.image} style={{ zIndex: "-1" }} />
                        </div>

                        <div
                          style={{ textDecoration: "none" }}
                        >
                           <div className="recent_project_button">
                          <Subheading
                            title={items.title}
                            style={{
                              textAlign: "center",
                              textTransform: "uppercase",
                            }}
                          />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })
            )}
          </Swiper>
        </div>

        <div
          style={{
            height: "max-content",
            width: "max-content",
            margin: "1rem auto",
          }}
        >
          <br />
          <NavLink to={"/all-recent-project"}>
            <ReadMore />
          </NavLink>
        </div>
      </section>
    </>
  );
};
export { RecentProjectSection };
