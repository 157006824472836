import React, { useEffect, useState } from "react";
import Header from "../../../CommonComponents/header";
import { Footer } from "../../../CommonComponents/footer";
import { Img } from "../../../subComponents/img";
import { Heading } from "../../../subComponents/Heading";
import { Subheading } from "../../../subComponents/subHeading";
import { RightImagesTextcard } from "../Components/rightImageTextCard";
import { LeftImagesTextCard } from "../Components/leftImagesTextcard";
import { ProductCategoryHeroSection } from "../Components/productCategoryHeroSection";
import { WhyProductCategorySection } from "../Components/whyProductCategorysection";
import { MobileHeader } from "../../../CommonComponents/mobileHeader";
import { useParams } from "react-router-dom";
import { baseurl } from "../../../CommonComponents/baseurl";

const UpvcProductPage = () => {

  return (
    <>
      <Header/>
      <MobileHeader/>
      <ProductCategoryHeroSection title={"uPVC door & Window"} content={"Think Once, Think Twice, Think UPVC door and window."} show={false} showpvc={true} />

      <WhyProductCategorySection title={"Why we use uPVC doors and window?"} content={`        
            It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes.`} />

      <section className="upvc_benefit_section" >
         <div style={{width:"max-content",margin:"auto",padding:"2rem 0"}} >
            <Heading title={"Benefits of uPVC"} />
         </div>

         <RightImagesTextcard orangeheading={"Technologically"} title=" - Advanced" content={"Modular Aluminium partition system is designed to quickly generate traditional drywall installation."} imageurl={"assets/images/product/ommercial partition sytem1.png"} />
        <LeftImagesTextCard orangeheading={"Sed ut perspiciatis"} title={" unde omnis iste natus et"} content={"Modular Aluminium partition system is designed to quickly generate traditional drywall installation."} imageurl={"assets/images/product/ommercial partition sytem 2.png"} />
        <RightImagesTextcard orangeheading={"Technologically"} title=" - Advanced" content={"Modular Aluminium partition system is designed to quickly generate traditional drywall installation."} imageurl={"assets/images/product/ommercial partition sytem1.png"} />
        <LeftImagesTextCard orangeheading={"Sed ut perspiciatis"} title={" unde omnis iste natus et"} content={"Modular Aluminium partition system is designed to quickly generate traditional drywall installation."} imageurl={"assets/images/product/ommercial partition sytem 2.png"} />

      </section>
      <br />
      
      <Footer />
    </>
  );
};
export { UpvcProductPage };
