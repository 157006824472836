import React, { useEffect } from "react";
import { Img } from "../../../subComponents/img";
import { Text } from "../../../subComponents/text";
import { ReadMore } from "../../../CommonComponents/readMore";
import { Subheading } from "../../../subComponents/subHeading";

import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

const AboutSection = ({}) => {

   useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);

    return(
        <>
          <section className="about_section" style={{overflowX:"hidden"}} >
            {/* container left start from here */}
            <div className="about_left_container" data-aos="fade-right" >
                <div className="about_left_image_container">
                  <Img src={"assets/images/Home/about alu empire1.png"} alt={"about alu empire"} />
                </div>
                <div className="about_left_floating_image">
                   <Img src={"assets/images/Home/about alu empire images.png"} alt={"about alu empire images"} />
                </div>
            </div>
            {/* container left close from here */}

            {/* container right start from here */}
            <div className="about_right_container" data-aos="fade-left" >
                <div className="about_right_innercontainer">
                  <Subheading title={"About ALU EMPIRE"} style={{color:"#FA853D", fontSize:"1rem"}} />
                  <h2 style={{fontWeight:"600", marginTop:"1rem"}}>WE PROVIDE THE BEST</h2>
                  <h2 style={{fontWeight:"600"}}>uPVC DOORS & WINDOWS</h2>
                  <div style={{marginTop:"1.4rem"}} >
                     <Text text={"Alu Empire provides a unique selection of premium uPVC, aluminum, and solid panel doors as well as windows and doors that are intended to improve the beauty of your house."}/>
                  </div>
                  <div style={{display:"flex", alignItems:"center", marginTop:"1rem"}} >
                     <Text text={"We offers a range of high-quality UPVC doors and windows designed to enhance your home's aesthetics and functionality—our customizable options to suit your unique preferences."}/>
                     <div style={{width:"10rem"}}>
                        <Img src={"assets/images/Home/about alu empire door.png"} alt={"about alu empire door"} style={{filter:"drop-shadow(0px 5px 4px #a5a4a4d3)"}} />
                     </div>
                  </div>
                  <div style={{display:"flex", alignItems:"center", gap:"1rem", marginTop:"1rem"}} >
                  <div style={{width:"20rem"}}>
                        <Img src={"assets/images/Home/design png.png"} alt={"about alu empire door"} style={{filter:"drop-shadow(0px 5px 4px #a5a4a4d3)"}} />
                     </div>
                     <Text text={"With an extensive range of customization options, Alu Empire Windows gives you the freedom to create custom doors that perfectly complement the aesthetic and style of your spaces at affordable uPVC doors price."}/>
                  </div>
                    <div style={{marginTop:"1rem"}} >
                       <NavLink to={"/about-us"} ><ReadMore/></NavLink>
                    </div>
                </div>
            </div>
            {/* container right close from here */}
          </section>
        </>
    );
}
export {AboutSection}