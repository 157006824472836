import React, { useState } from "react";
import { Subheading } from "../../../subComponents/subHeading";
import { Text } from "../../../subComponents/text";
import { Img } from "../../../subComponents/img";
import { baseurl } from "../../../CommonComponents/baseurl";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "2rem",
  boxShadow: 24,
  // p: 4,
};

const GetInTouchSection = () => {
  const [customer_name, setCustomerName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [required_service, setRequiredService] = useState("uPVC");
  const [otherdescription, setOtherDescription] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [open, setOpen] = React.useState(false);



  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const validateForm = () => {
    if (!customer_name) {
      setError("Name is required");
      handleClick();
      return false;
    }
    if (!phone_number) {
      setError("Phone number is required");
      handleClick();
      return false;
    }
    // if (!email) {
    //   setError("Email is required");
    //   handleClick();
    //   return false;
    // }
    if (required_service === "other" && !otherdescription) {
      setError("Please specify the service");
      handleClick();
      return false;
    }
    setError(""); // Clear any previous error
    return true;
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values
    if (/^\d*$/.test(value)) {
      if (value.length <= 15) {
        setPhoneNumber(value);
        setPhoneNumberError("");
      } else {
        setPhoneNumberError("Cannot enter more than 15 characters");
        handleClick();
      }
    } else {
      setPhoneNumberError("Only numeric values are allowed");
      handleClick();
    }
  };

  const formSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch(`${baseurl}/api/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_name,
          phone_number,
          email,
          required_service,
          otherdescription,
        }),
      });

      const data = await response.json();
      if (data.success === "1") {
        setSuccessMessage(data.message);
        clearForm();
        handleClick();
        // handleOpen2();
      } else {
        console.error("Failed to submit data:", data.message);
        setError(data.message);
        console.log(data.message);
        handleClick();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setError("An error occurred. Please try again.");
      handleClick();
    }
  };

  const clearForm = () => {
    setCustomerName("");
    setPhoneNumber("");
    setEmail("");
    setRequiredService("uPVC");
    setOtherDescription("");
  };

  return (
    <>
      <section className="getin_touch_section">
        <div className="getin_touch_container" id="quiery-form">
        <div className="getin_touch_left_container">
            <Subheading orangetext={"Get In"} title={" Touch"} />
            <Text
              style={{ marginTop: "1rem" }}
              text={"If you have any query, feel free to ask for details."}
            />
            <div className="contact_info">
              <div className="address_container">
                <div className="address_image">
                  <Img
                    src={"assets/images/Contactus/phone.png"}
                    alt={"phone.png"}
                  />
                </div>
                <div>
                  <Subheading
                    style={{ fontSize: "1rem", color: "#FA853D" }}
                    title={"Phone Number"}
                  />
                  <Text
                    text={"+91  9002690068"}
                    style={{ marginBottom: "0", marginTop: "0.3rem" }}
                  />
                </div>
              </div>
              <div className="address_container">
                <div className="address_image">
                  <Img
                    src={"assets/images/Contactus/email.png"}
                    alt={"email.png"}
                  />
                </div>
                <div>
                  <Subheading
                    style={{ fontSize: "1rem", color: "#FA853D" }}
                    title={"E-mail"}
                  />
                  <Text
                    text={"info@aluempire.com"}
                    style={{ marginBottom: "0", marginTop: "0.3rem" }}
                  />
                </div>
              </div>
              <div className="address_container" style={{ alignItems: "start" }}>
                <div className="address_image">
                  <img
                    src={"assets/images/Contactus/location.png"}
                    alt={"location.png"}
                    height={"100%"}
                  />
                </div>
                <div>
                  <Subheading
                    style={{ fontSize: "1rem", color: "#FA853D" }}
                    title="Address"
                  />
                  <Text
                    style={{ marginBottom: "0", marginTop: "0.3rem" }}
                    highlightText="Head Office:"
                    text={`Plot No. 18, Gali No.1, Pali Road Bhakri, Asharam Bapu Ashram, Sikri Industrial Area, Faridabad, Haryana - 121004`}
                  />
                  <br />
                  <Text
                    style={{ marginBottom: "0", marginTop: "0.3rem" }}
                    showroomhighlight="Our Showroom:"
                    text={`Katla Bazar Madanganj, Kishangarh, Rajasthan - 305801`}
                  />
                  <br />
                  <Text
                    style={{ marginBottom: "0", marginTop: "0.3rem" }}
                    showroomhighlight="Our Showroom:"
                    text={`Shop No. 226,227, New Aatish Market, Gopalpur Bye Pass, Jaipur, Rajasthan - 302019`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="getin_touch_right_container">
            <div className="innergetin_touch_right_container shadow">
              <Text
                style={{ color: "#fff", fontSize: "1.3rem" }}
                text={"Tell us about your project"}
              />
              <div className="form_input_feild_container">
                <div className="pricing_container">
                  <div className="input_feild_title">
                    <p style={{ marginBottom: "0", color: "#fff" }}>Name</p>
                  </div>
                  <div className="input_feild">
                    <input
                      className="contact_form_input_feild"
                      type="text"
                      placeholder="Enter your name"
                      maxLength={35}
                      value={customer_name}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="pricing_container">
                  <div className="input_feild_title">
                    <p style={{ marginBottom: "0", color: "#fff" }}>
                      Phone Number
                    </p>
                  </div>
                  <div className="input_feild">
                    <input
                      className="contact_form_input_feild"
                      type="text"
                      placeholder="Enter your phone number"
                      maxLength={13}
                      value={phone_number}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                </div>
                <div className="pricing_container">
                  <div className="input_feild_title">
                    <p style={{ marginBottom: "0", color: "#fff" }}>
                      I’m looking for
                    </p>
                  </div>
                  <div className="input_feild">
                    <select
                      name="requiredService"
                      id="requiredService"
                      required
                      value={required_service}
                      onChange={(e) => setRequiredService(e.target.value)}
                    >
                      <option value="uPVC">uPVC</option>
                      <option value="ALU Home Interior">
                        ALU Home Interior
                      </option>
                      <option value="Commercial partition system">
                        Commercial partition system
                      </option>
                      <option value="other">other</option>
                    </select>
                  </div>
                </div>

                {required_service === "other" ? (
                  <div className="pricing_container">
                    <div className="input_feild_title">
                      <p style={{ marginBottom: "0", color: "#fff" }}></p>
                    </div>
                    <div className="input_feild">
                      <input
                        className="contact_form_input_feild"
                        type="text"
                        placeholder="Please specify"
                        maxLength={50}
                        value={otherdescription}
                        onChange={(e) => setOtherDescription(e.target.value)}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="pricing_container">
                  <div className="input_feild_title">
                    <p style={{ marginBottom: "0", color: "#fff" }}>Email ID</p>
                  </div>
                  <div className="input_feild">
                    <input
                      type="email"
                      className="contact_form_input_feild"
                      placeholder="Enter your email"
                      maxLength={70}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <button className="form_btn" onClick={formSubmit}>
                  Submit
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 15 15"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      d="m13.5 7.5l-4-4m4 4l-4 4m4-4H1"
                    />
                  </svg>
                </button>

                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert onClose={handleClose} severity={error ? 'error' : 'success'}>
                    {error || successMessage || phoneNumberError}
                  </Alert>
                </Snackbar>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { GetInTouchSection };



      {/* modal 2 start from here */}
      // <Modal
      //   open={openmodal2}
      //   aria-labelledby="modal-modal-title"
      //   aria-describedby="modal-modal-description"
      // >
      //   <Box sx={style}>
      //     <div className="innergetin_touch_right_container shadow">
      //       <div style={{marginLeft:"96%", marginTop:"-1rem"}} onClick={handleClosemodal2} ><h5 style={{color:"#fff"}}>X</h5></div>
      //        <div style={{height:"200px", width:"300px", display:"flex", alignItems:"center", flexDirection:"column"}}>
      //         <br />
      //         <h1 style={{textAlign:"center", color:"#fff"}}>Submitted successfully</h1>
      //         <div style={{height:"60px"}}></div>
      //         <Button style={{backgroundColor:"#fa853d", height:"40px", color:"#fff"}} onClick={handleClosemodal2}>OK</Button>
      //        </div>
      //     </div>
      //   </Box>
      // </Modal>
      {/* modal  close from here */}