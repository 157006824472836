import React, { useEffect } from "react";
import { Heading } from "../../../../subComponents/Heading";
import AOS from "aos";
import "aos/dist/aos.css";

const VisionCard=({title})=>{

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

    return(
        <>
          <div className="vision_card shadow" data-aos="fade-left" >
             <Heading title={title} />
          </div>
        </>
    );
}
export {VisionCard};
