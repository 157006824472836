import React from "react";
const style ={
    fontSize:"0.865rem",
    color:"#000",
    fontWeight:"400"
} 

const Text = ({ showroomhighlight,highlightText, text,...restprops})=>{
    return(
        <>
        <p style={style} {...restprops} ><h4 style={{color:"#FA853D", fontSize:"1rem"}}>{showroomhighlight}</h4><strong>{highlightText}</strong> {text}</p>
        </>
    )
}
export {Text}