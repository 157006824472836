import React, { useEffect } from "react";
import { Heading } from "../../../subComponents/Heading";
import { Text } from "../../../subComponents/text";
import { Img } from "../../../subComponents/img";
import AOS from "aos";
import "aos/dist/aos.css";

const LeftImagesTextCard=({title,content,imageurl,orangeheading})=>{

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return(
        <>
          <section className="right_image_text_product" style={{overflowX:"hidden"}} >
            {/* product_text_image_right_container start from here */}
            <div className="product_text_image_right_container" data-aos="fade-right" >
                <div className="product_text_image">
                   <Img src={imageurl} alt={"ommercial partition sytem1"} />
                </div>
            </div>
            {/* product_text_image_right_container close from here */}


            {/* product_text_image_left_container start from here */}
            <div className="product_text_image_left_container" data-aos="fade-left"  >
                <Heading style={{fontSize:"0.8rem"}} orangeheading={orangeheading} title={title}  />
                <div className="product_text_image_content">
                    <Text text={content} />
                </div>
            </div>
            {/* product_text_image_left_container close from here */}
          </section>
        </>
    );
}
export {LeftImagesTextCard};